/**
 * 需求信息实体类和接口
 */
import { ReqPage } from '@/model/common/reqPage'
export class Achievement {
  demandNo: string | undefined // 需求编号"
  quoteQty: string | undefined //询价数量
  quoteTotalMoney: string | undefined //询价金额
  quoteTimes: string | undefined //询价次数
  quoteCustomerCount: string | undefined //询价家数
  dealAmount: string | undefined //成交金额
  dealTimes: string | undefined //成交次数
  dealCustomerCount: number | undefined //成交家数
  productNumber: string | undefined //临时型号
  isPositive: number | undefined //	是否转正式型号（0-否；1-是）
  officialProductNumber: string | undefined //正式型号
  createdTime: string | undefined //提交时间
  finishDate: string | undefined //完成时间
  constructor() {
    this.demandNo = undefined
    this.quoteQty = undefined
    this.quoteTotalMoney = undefined
    this.quoteTimes = undefined
    this.quoteCustomerCount = undefined
    this.dealAmount = undefined
    this.dealTimes = undefined
    this.dealCustomerCount = undefined
    this.productNumber = undefined
    this.isPositive = undefined
    this.officialProductNumber = undefined
    this.createdTime = undefined
    this.finishDate = undefined
  }
}
//分页查询类
export class RegAchievement extends ReqPage {
  data: Achievement
  constructor() {
    super()
    this.data = new Achievement()
  }
}
export interface ResAchievementClass extends ReqPage {
  data: Achievement[]
}
export class AchievementDetail {
  demandNo: string | undefined // 需求编号"
  contact: string | undefined //'联系人'
  contactPhone: string | undefined //联系电话
  companyName: string | undefined //公司名称
  demandProduct: string | undefined //需求产品名称
  deviceName: string | undefined //应用设备名称
  assignee: string | undefined //开发人
  productNumber: string | undefined //临时型号
  serialNumber: string | undefined //序号
  customerName: string | undefined //客户名称
  customerCode: string | undefined //客户编号
  salesman: string | undefined //业务员
  primaryIndustry: string | undefined //所属行业-一级行业
  secondaryIndustry: string | undefined //所属行业-二级行业
  industry: string | undefined //所属行业
  taxPrice: string | undefined //含税单价
  quoteQty: string | undefined //询价数量
  quoteTotalMoney: string | undefined //询价金额
  dealPrice: string | undefined //成交单价
  dealQty: string | undefined //成交数量
  dealAmount: string | undefined //成交金额
  quoteDate: string | undefined //询价时间
  dealDate: string | undefined //完成时间
  quoteTimes: string | undefined //询价次数
  quoteCustomerCount: string | undefined //询价家数
  officialProductNumber: string | undefined //正式型号
  finishDate: string | undefined //完成时间
  constructor() {
    this.demandNo = undefined
    this.contact = undefined
    this.contactPhone = undefined
    this.companyName = undefined
    this.demandProduct = undefined
    this.deviceName = undefined
    this.assignee = undefined
    this.productNumber = undefined
    this.serialNumber = undefined
    this.customerName = undefined
    this.customerCode = undefined
    this.salesman = undefined
    this.primaryIndustry = undefined
    this.secondaryIndustry = undefined
    this.industry = undefined
    this.taxPrice = undefined
    this.quoteQty = undefined
    this.quoteTotalMoney = undefined
    this.dealPrice = undefined
    this.dealQty = undefined
    this.dealAmount = undefined
    this.quoteDate = undefined
    this.dealDate = undefined
    this.quoteTimes = undefined
    this.quoteCustomerCount = undefined
    this.officialProductNumber = undefined
    this.finishDate = undefined
  }
}
//分页查询类
export class RegAchievementDetail extends ReqPage {
  data: AchievementDetail
  constructor() {
    super()
    this.data = new AchievementDetail()
  }
}
export interface ResAchievementDetailClass extends ReqPage {
  data: AchievementDetail[]
}
