import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  style: { color: '#13c2c2', cursor: 'pointer' }
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_yTable = _resolveComponent("yTable")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_listLayout = _resolveComponent("listLayout")!

  return (_openBlock(), _createBlock(_component_listLayout, null, {
    selectList: _withCtx(() => [
      _createVNode(_component_a_form, {
        layout: "inline",
        model: _ctx.selectFrom.data,
        "label-col": _ctx.labelCol
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItemList, (item) => {
            return (_openBlock(), _createBlock(_component_a_form_item, {
              key: item.prop,
              label: item.label,
              name: item.prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormItem, {
                  value: _ctx.selectFrom.data[item.prop],
                  "onUpdate:value": ($event: any) => ((_ctx.selectFrom.data[item.prop]) = $event),
                  formItemContent: item
                }, null, 8, ["value", "onUpdate:value", "formItemContent"])
              ]),
              _: 2
            }, 1032, ["label", "name"]))
          }), 128)),
          _createVNode(_component_a_form_item, {
            class: "button",
            label: " ",
            colon: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.selectList
              }, {
                default: _withCtx(() => [
                  _createTextVNode("查询")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, { onClick: _ctx.reset }, {
                default: _withCtx(() => [
                  _createTextVNode("重置")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "label-col"])
    ]),
    buttonList: _withCtx(() => [
      _createElementVNode("span", {
        class: "iconfont icon-lieshezhi pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowDrawer = true))
      })
    ]),
    table: _withCtx(() => [
      _createVNode(_component_yTable, {
        dataSource: _ctx.dataSource,
        columns: _ctx.columns,
        selectedRowKeys: _ctx.selectedRowKeys,
        "onUpdate:selectedRowKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRowKeys) = $event)),
        currentConfigure: _ctx.currentConfigure,
        rowKey: 'demandNo'
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.dataIndex === 'demandNo')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(record.demandNo), 1))
            : (column.dataIndex === 'operation')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("span", {
                    class: "edit",
                    onClick: ($event: any) => (_ctx.goDetail(record)),
                    style: { color: '#13c2c2', 'margin-right': '16px', cursor: 'pointer' }
                  }, " 详情 ", 8, _hoisted_2),
                  _createElementVNode("span", {
                    onClick: ($event: any) => (_ctx.demandFollow(record.id, record.isFollow)),
                    style: { color: '#4288FE', cursor: 'pointer' }
                  }, _toDisplayString(record.isFollow === 1 ? '取消关注' : '关注'), 9, _hoisted_3)
                ], 64))
              : (column.dataIndex === 'isEnable')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("i", {
                      class: "fa fa-circle",
                      style: _normalizeStyle({
                'font-size': '10px',
                color: record.isEnable === 1 ? '#13c2c2' : '#ccc',
                'margin-right': '5px',
              })
                    }, null, 4),
                    _createTextVNode(_toDisplayString(record.isEnable === 1 ? '已启用' : '未启用'), 1)
                  ], 64))
                : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["dataSource", "columns", "selectedRowKeys", "currentConfigure"]),
      _createVNode(_component_Drawer, {
        visible: _ctx.isShowDrawer,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowDrawer) = $event)),
        "origin-columns": _ctx.columns,
        "page-name": "resultsManagement",
        onUpdateColumns: _ctx.updateColumns
      }, null, 8, ["visible", "origin-columns", "onUpdateColumns"])
    ]),
    _: 1
  }))
}