
import { defineComponent, reactive, toRefs, computed, nextTick } from 'vue'
import { Achievement, RegAchievement } from '@/model/industryManagement/achievement'
import listLayout from '@/components/listLayout/listLayout.vue'
import FormItem from '@/components/formItem/index.vue'
// import editButton from '@/components/editButton/index.vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import { deleteModal } from '@/utils/deleteModal'
// import Data from '@/model/common/Data'
import AchieveApi from '@/axios/api/achievementApi'
import proposalApi from '@/axios/api/proposal'
import { FormItemListType } from '@/types/comm'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    FormItem,
    // editButton,
    yTable,
    Drawer,
  },
  setup() {
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    //获取路由
    let router = useRouter()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new RegAchievement(),
      dataSource: [] as Achievement[],
      columns: [
        {
          title: '需求编号',
          dataIndex: 'demandNo',
          key: 'demandNo',
          width: 150,
          resizable: true,
        },
        {
          title: '询价数量',
          dataIndex: 'quoteQty',
          key: 'quoteQty',
          width: 110,
          resizable: true,
        },
        {
          title: '询价金额',
          dataIndex: 'quoteTotalMoney',
          key: 'quoteTotalMoney',
          width: 110,
          resizable: true,
        },
        {
          title: '询价次数',
          dataIndex: 'quoteTimes',
          key: 'quoteTimes',
          width: 80,
          resizable: true,
        },
        {
          title: '询价家数',
          dataIndex: 'quoteCustomerCount',
          key: 'quoteCustomerCount',
          width: 80,
          resizable: true,
        },
        {
          title: '成交金额',
          dataIndex: 'dealAmount',
          key: 'dealAmount',
          width: 80,
          resizable: true,
        },
        {
          title: '成交次数',
          dataIndex: 'dealTimes',
          key: 'dealTimes',
          width: 80,
          resizable: true,
        },
        {
          title: '成交家数',
          dataIndex: 'dealCustomerCount',
          key: 'dealCustomerCount',
          width: 80,
          resizable: true,
        },
        {
          title: '是否转正式',
          dataIndex: 'isPositive',
          key: 'isPositive',
          width: 100,
          statusObj: {
            0: '否',
            1: '是',
          },
          resizable: true,
        },
        {
          title: '产品名称',
          dataIndex: 'demandProduct',
          key: 'demandProduct',
          width: 110,
          resizable: true,
        },
        {
          title: '临时型号',
          dataIndex: 'productNumber',
          key: 'productNumber',
          width: 110,
          resizable: true,
        },
        {
          title: '正式型号',
          dataIndex: 'officialProductNumber',
          key: 'officialProductNumber',
          width: 110,
          resizable: true,
        },
        // {
        //   title: '添加人',
        //   dataIndex: 'createdBy',
        //   key: 'createdBy',
        //   width: 120,
        //   resizable: true,
        // },
        {
          title: '转提案人',
          dataIndex: 'createdBy',
          key: 'createdBy',
          width: 120,
          resizable: true,
        },
        {
          title: '转提案人部门',
          dataIndex: 'dept',
          key: 'dept',
          width: 180,
          resizable: true,
        },
        {
          title: '提交时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          width: 120,
          resizable: true,
          customRender: ({ text }: { text: string }) => text?.substr(0, 10),
        },
        {
          title: '完成时间',
          dataIndex: 'finishDate',
          key: 'finishDate',
          width: 120,
          resizable: true,
          customRender: ({ text }: { text: string }) => text?.substr(0, 10),
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'demandNo',
          placeholder: '请输入需求编号',
          label: '需求编号',
        },
        {
          type: 'subInput',
          prop: 'productNumber',
          placeholder: '请输入产品型号',
          label: '产品型号',
        },
        {
          type: 'subSelect',
          prop: 'isPositive',
          placeholder: '请选择是否转正式',
          label: '是否转正式:',
          optionList: [
            {
              value: 0,
              label: '否',
            },
            {
              value: 1,
              label: '是',
            },
          ],
        },
        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请选择是否关注',
          label: '是否关注:',
          optionList: [
            {
              value: 0,
              label: '未关注',
            },
            {
              value: 1,
              label: '关注',
            },
          ],
        },
        {
          type: 'subInput',
          prop: 'createdBy',
          placeholder: '请输入完整转提案人名称',
          label: '转提案人',
        },
      ] as FormItemListType<Achievement>,
    })
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.selectedRowKeys.length = 0
      AchieveApi.ListDemandAchievement(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
        })
        .catch((err) => {
          message.error(err)
        })
    }
    //重置数据
    const selectList = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据

    selectList()
    //新增按钮
    const addData = () => {
      router.push('/industryManagement/demandAdd')
    }
    //删除按钮
    const delData = () => {
      if (pageData.selectedRowKeys.length === 0) {
        message.warning('至少选中一条')
        return
      }
      let data = pageData.selectedRowKeys.map((item) => {
        return {
          id: item,
        }
      })
      const ok = () => {
        AchieveApi.queryOptionalType(data)
          .then(() => {
            message.success('删除成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel)
    }
    //
    const reset = () => {
      pageData.selectFrom.data = new Achievement()
      selectList()
    }
    //跳转到详情
    const goDetail = (data: Achievement) => {
      store.commit('setResultsDetailForm', data)
      router.push(`/industryManagement/resultsDetail/${data.demandNo}`)
    }
    //关注与取消关注页面
    const demandFollow = (id: string, isFollow: number) => {
      if (isFollow === 1) {
        proposalApi
          .demandFollowCancel(id)
          .then(() => {
            message.success('取消关注成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        proposalApi
          .demandFollow(id)
          .then(() => {
            message.success('关注成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }
    return {
      goDetail,
      ...toRefs(pageData),
      buttonObj,
      currentConfigure,
      addData,
      delData,
      labelCol: { style: { width: '100px' } },
      reset,
      selectList,
      initeList,
      demandFollow,
      updateColumns,
    }
  },
})
